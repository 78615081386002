<template>
  <v-container
    fluid
    class="pa-0 pl-3"
  >  
    <v-card 
      class="py-6 px-6 app-height-global mt-3"
      rounded="lg"
      elevation="0"
      width="100%"
    >
      <v-row>
        <v-col flex>
          <div
            class="back-to-projects"
            @click="handleBackClick"
          >
            <v-icon color="blue">
              mdi-chevron-left
            </v-icon>
            <p class="d-flex-inline justify-center align-center ma-0 blue--text font-weight-bold">
              {{ $t('testruns.create_testrun.back_to_testrun') }}
            </p>
          </div>
        </v-col>
      </v-row>
      <template v-if="!loading">
        <v-row justify="center">
          <v-col
            md="5"
            lg="4"
            class="mb-3"
          >
            <h2 class="text-start">
              {{ $t('testruns.create_testrun.title') }}
            </h2>
          </v-col>
        </v-row>
        <v-form
          ref="runForm"
          role="createRunForm"
          class="text-left"
        >
          <v-row justify="center">
            <v-col
              md="5"
              lg="4"
            >
              <v-label class="text-left fs-14px text-theme-label font-weight-medium">
                {{ $t('testruns.create_testrun.testrun_name') }}<strong class="red--text text--lighten-1">*</strong>
              </v-label>
              <v-text-field
                v-model="selectedRun.name"
                type="text"
                dense
                :placeholder="$t('testruns.create_testrun.testRunName')"
                height="38px"
                :rules="requiredRule"
                class="rounded-lg field-theme"
                background-color="#F9F9FB"
              />
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col
              md="5"
              lg="4"
              class="pt-0"
            >
              <v-label class="text-left fs-14px text-theme-label font-weight-medium">
                {{ $t('testruns.create_testrun.description') }}
              </v-label>
              <v-text-field
                v-model="selectedRun.description"
                type="text"
                class="rounded-lg field-theme"
                background-color="#F9F9FB"
                dense
                height="38px"
                :placeholder="$t('description')"
              />
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col
              md="5"
              lg="4"
              class="pt-0"
            >
              <v-label class="text-left fs-14px text-theme-label font-weight-medium">
                {{ $t('testruns.create_testrun.milestone') }}
              </v-label>
              <v-select
                v-model="selectedRun.milestoneUids"
                type="text"
                dense
                background-color="#F9F9FB"
                multiple
                :placeholder="$t('milestone.choose')"
                :items="milestones"
                class="rounded-lg field-theme combo-box custom-prepend mh-38px"
                append-icon="mdi-chevron-down"
                item-text="name"
                item-value="uid"
                :menu-props="{ offsetY: true }"
              >
                <template v-slot:selection="{ item }">
                  <div
                    class="d-flex align-center custom-chip-theme mr-1 mb-1"
                  >
                    <div class="text-theme-label label text-truncate mr-1">
                      {{ item.name }}
                    </div>
                    <v-icon
                      size="16px"
                      @click="onRemoveSelectedMilestone(item.uid)"
                    >
                      mdi-close
                    </v-icon>
                  </div>
                </template>

                <template v-slot:item="{ item, on, attrs }">
                  <v-list-item
                    :ripple="false"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-list-item-action>
                      <v-checkbox
                        hide-details
                        :input-value="milestoneSelection(item.uid)"
                        class="field-theme mt-0 pt-0"
                        :ripple="false"
                        off-icon="icon-checkbox-off"
                        on-icon="icon-checkbox-on"
                      >
                        <template v-slot:label>
                          <span class="fs-14px text-theme-label">{{ `${item.name}` }}</span>
                        </template>
                      </v-checkbox>
                    </v-list-item-action>
                  </v-list-item>
                </template>
              </v-select>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col
              md="5"
              lg="4"
              class="pt-0"
            >
              <v-label class="text-left fs-14px text-theme-label font-weight-medium">
                {{ $t('testruns.create_testrun.priority') }}
              </v-label>
              <v-select
                v-model="selectedRun.priority"
                type="text"
                dense
                background-color="#F9F9FB"
                height="38px"
                :placeholder="$t('choosePriority')"
                :items="priorities"
                class="rounded-lg field-theme combo-box custom-prepend"
                append-icon="mdi-chevron-down"
              />
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col
              md="5"
              lg="4"
              class="pt-0"
            >
              <v-label class="text-left fs-14px text-theme-label font-weight-medium">
                {{ $t('testruns.create_testrun.tags') }}
              </v-label>
              <v-select
                v-model="selectedRun.tags"
                type="text"
                dense
                background-color="#F9F9FB"
                :placeholder="$t('chooseTags')"
                :items="tags"
                class="rounded-lg field-theme custom-prepend mh-38px"
                append-icon="mdi-chevron-down"
                :menu-props="{ offsetY: true }"
                multiple
              >
                <template v-slot:selection="{ item }">
                  <div
                    class="d-flex align-center custom-chip-theme mr-1 mb-1"
                  >
                    <div class="text-theme-label label text-truncate mr-1">
                      {{ item }}
                    </div>
                    <v-icon
                      size="16px"
                      @click="onRemoveSelectedTags(item)"
                    >
                      mdi-close
                    </v-icon>
                  </div>
                </template>

                <template v-slot:item="{ item, on, attrs }">
                  <v-list-item
                    :ripple="false"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-list-item-action>
                      <v-checkbox
                        hide-details
                        :input-value="tagsSelection(item)"
                        class="field-theme mt-0 pt-0"
                        :ripple="false"
                        off-icon="icon-checkbox-off"
                        on-icon="icon-checkbox-on"
                      >
                        <template v-slot:label>
                          <span class="fs-14px text-theme-label">{{ `${item}` }}</span>
                        </template>
                      </v-checkbox>
                    </v-list-item-action>
                  </v-list-item>
                </template>
              </v-select>
            </v-col>
          </v-row>
        </v-form>
      </template>
      <v-container
        v-else 
        style="height: 60vh;"
      >
        <Loader 
          class="projects-loader"
        />
      </v-container>
     
      <CaseManagement 
        v-if="showSelectCases"
        :quick-create="false" 
        :show-collapse="false"
        :cases="cases"
        @folder-select="getCases"
        @selectedCases="handleCases"
        @update-cases="updateCases"
      />
      <v-row
        v-if="!loading"
        justify="end"
        class="mt-4"
      >
        <v-col cols="12">
          <v-flex class="mt-6 d-sm-flex justify-end">
            <v-menu
              v-model="menuOpen"
              top
              offset-y
              class="rounded-lg"
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  background-color="#F2F4F7"
                  class="text-capitalize btn-theme"
                  :width="$vuetify.breakpoint.smAndDown ? '100%' : '150px'"
                  height="38px"
                  v-on="on"
                >
                  {{ $t('testruns.create_testrun.actions') }}<v-icon>{{ menuOpen ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                </v-btn>
              </template>
              <v-list
                dense
                class="text-left"
              >
                <v-list-item @click="handleDuplicateClick">
                  <v-list-item-title>{{ $t('testruns.create_testrun.duplicate_testrun') }}</v-list-item-title>
                </v-list-item>
                <v-list-item @click="handleAddCases">
                  <v-list-item-title>{{ $t('testruns.create_testrun.add_testcase') }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-btn
              color="primary"
              depressed
              class="f-color-white ml-2 btn-theme text-capitalize"
              :width="$vuetify.breakpoint.smAndDown ? '100%' : '150px'"
              height="38px"
              @click="createTestRuns"
            >
              {{ $t('testruns.create_testrun.title') }}
            </v-btn>
          </v-flex>
        </v-col>
      </v-row>
    </v-card>
    <ProjectDiscardDialog
      v-model="showConfirmBackDialog"
      :title="$t('testruns.create_testrun.close_dialog.title')"
      @close="handleCloseClick"
      @handleConfirmClick="handleConfirmClick"
    />
  </v-container>
</template>

<script>
import ProjectDiscardDialog from '@/components/Project/ProjectDiscardDialog.vue';
import CaseManagement from '@/components/Cases/CaseManagement'
import { mapState, createNamespacedHelpers } from 'vuex';
import makeCasesService from '@/services/api/case'
import MilestoneService from '@/services/api/milestone';
import {  showErrorToast } from '@/utils/toast';
import Loader from '@/components/base/Loader';
import { handleNetworkStatusError } from '@/mixins/redirect';
import makeTagsService from '@/services/api/tag';
const { mapActions, mapMutations } = createNamespacedHelpers('run');
const { mapMutations: mapMilestoneMutations } = createNamespacedHelpers('milestone');
let makeMilestoneService;
let tagService;

export default {
  name: 'TestRunCreate',
  components: {
    ProjectDiscardDialog,
    CaseManagement,
    Loader,
  },
   mixins: [handleNetworkStatusError],
  data() {
    return {
      requiredRule: [v => !!v || this.$t('thisFieldIsRequired')],
      selectedRun: {},
      menuOpen: false,
      showConfirmBackDialog: false,
      search: '',
      selectedCases: [],
      showSelectCases: false,
      milestones: [],
      priorities: ["High", "Medium", "Low"],
      tags: [],
      cases:[],
      loading: false,
    };
  },
  
  computed: {
    ...mapState('user',['currentAccount']),
  },
    created() {
    makeMilestoneService = MilestoneService(this.$api);
    tagService = makeTagsService(this.$api);
  },
    async mounted() {
      this.init();
      await this.getAllTags();
  },
  methods: {
    
    ...mapActions(['createTestRun']),
    ...mapMutations(['UPDATE_SELECTED', 'SET_ACTION_TYPE', 'SET_ADD_NEW_RUN']),
    async getCases(folderUID){
      const caseService = makeCasesService(this.$api);
      const handle = this.$route.params.handle;
      const projectKey = this.$route.params.key;

      await caseService.getFolderCases(handle, projectKey, folderUID).then(response => {
          this.cases = response.data.cases;

      }).catch(err => {
        console.log(err)
      })
    },
      async init() {
      try {
        await this.getMilestones(); 
      } catch (error) {
        this.redirectOnError(error.response.status);
        showErrorToast(this.$swal, 'fetchError', { item: 'Milestone' });
      } 
    },

    milestoneSelection(uid) {
      return this.selectedRun.milestoneUids ? this.selectedRun.milestoneUids.some((item) => item === uid) : false;
    },
    onRemoveSelectedMilestone(uid) {
      const index = this.selectedRun.milestoneUids.findIndex((item) => item === uid);
      if (index !== -1) {
        this.selectedRun.milestoneUids.splice(index, 1);
      }
    },
    onRemoveSelectedTags(name) {
      const index = this.selectedRun.tags.findIndex((item) => item === name);
      if (index !== -1) {
        this.selectedRun.tags.splice(index, 1);
      }
    },
    tagsSelection(name) {
      return this.selectedRun.tags ? this.selectedRun.tags.some((item) => item === name) : false;
    },
    async getAllTags() {
      try {
        const response = await tagService.getTags(
          this.$route.params.handle,
          this.$route.params.key
        );
        if (response.status === 200) {
          this.tags = response.data.map(tag => tag.name);
        }
      } catch (error) {
        showErrorToast(this.$swal, "fetchError", { item: "tags" });
        console.error('Error fetching tags:', error);
      }
    },

    createTestRuns() {      // TODO - consolidate create and update between this and RunAddCaseView.vue
      if(this.$refs.runForm.validate()){
        const payload = {
          name: this.selectedRun.name,
          customFields: {
            assign: this.currentAccount.handle,
            description: this.selectedRun.description,
            tags: this.selectedRun.tags,
            progress: 0,
            star: true,
            caseCount: 0,
            archived: false,
          },
          priority: this.selectedRun.priority,
          status: 'Active',
          milestoneUids: this.selectedRun.milestoneUids,
          externalId: 'testfiesta',
          source: 'testfiesta-web',
          projectKey: this.$route.params.key,
        };

        if(this.selectedCases.length){
          const selectedCases = this.selectedCases.map(item => item.testCaseRef)
          payload.customFields.caseCount = selectedCases.length;
          payload.cases = selectedCases;
        }       
        this.createTestRun({ swal: this.$swal, handle: this.$route.params.handle, projectKey: this.$route.params.key, payload })
          .then(() => {
            this.UPDATE_SELECTED({});
            if(!this.$route.query.activeAddMilestone){
              this.$router.push({
                name: this.$route.query?.page?this.$route.query?.page:'Runs',
                params: {
                  handle: this.$route.params.handle,
                  key: this.$route.params.key
                }
              }); 
            } else {
              this.$router.push({
                name: 'MilestoneTestActivities',
                params: {
                  handle: this.$route.params.handle,
                  key: this.$route.params.key
                },
              });
            }
            
          })
          .catch(error => {
            console.error("Failed to create Test Run:", error);
          });
      }
    },
    handleCases(selectedCases){
      this.selectedCases = selectedCases
    },
    openFileDialog() {
      this.$refs.fileInput.click();
    },
    handleAddCases(){
      if(this.$refs.runForm.validate()){
        this.SET_ADD_NEW_RUN(this.selectedRun);
        this.SET_ACTION_TYPE('create');
        const query = this.$route.query.activeAddMilestone ? { activeAddMilestone: 'true' } : {};
        this.$router.push({
          name: 'RunAddCase',
          params: {
            handle: this.$route.params.handle,
            key: this.$route.params.key,
          },
          query: query
        });
      }
    },
    handleBackClick() {
      this.showConfirmBackDialog = true;
    },
    handleCloseClick() {
      this.showConfirmBackDialog = false;
    },
    handleDuplicateClick() {
      this.showConfirmBackDialog = false;
      this.$router.push({
        name: 'Runs',
        params: {
          handle: this.$route.params.handle,
          key: this.$route.params.key,
          RunViewType: 'Duplicate', // TODO - don't do this via param
        },
      });
    },
    handleConfirmClick() {
      this.UPDATE_SELECTED({});
      this.showConfirmBackDialog = false;
      this.$router.replace({
        name: 'Runs',
        params: {
          handle: this.$route.params.handle,
          key: this.$route.params.key
        }
      });
    },
     async getMilestones() {
      this.loading = true;
      try {
    
        const response = await makeMilestoneService.getMilestones(this.$route.params.handle, this.$route.params.key);
        this.milestones = response.data.milestones.map(milestone => ({name: milestone.name, uid: milestone.uid}));
      } catch (error) {
        this.redirectOnError(error.response.status);
        showErrorToast(this.$swal, 'fetchError', { item: 'milestones' });
        this.milestones = []; // Reset to empty array in case of error
      } finally {
        this.loading = false;
      }
    },
    updateCases(newCases) {
      this.cases = newCases;
    },
  },
};
</script>

<style lang="scss" scoped>
.back-to-projects {
  display: flex;
  cursor: pointer;
  width: max-content;
}
.search_input {
  width: 100%;
  @media screen and (min-width: 600px) {
    width: 300px;
  }
}
.project-logo {
  border-radius: 50%;
  border: 2px dashed grey;
  width: 150px;
  height: 150px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
  cursor: pointer;
  transition: border-color 0.3s;
  background-size: cover;
  background-position: center;
  position: relative;
}

.project-logo:hover {
  border-color: #2196f3;
}

.hovering .edit-icon,
.hovering .delete-icon {
  display: block;
}

.edit-icon,
.delete-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) translateX(-30px);
  display: none;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
}
.delete-icon {
  margin-left: 60px;
}

.project-logo:hover .edit-icon,
.project-logo:hover .delete-icon {
  display: block;
}
</style>
